import { Injectable } from '@angular/core';

import {
  getFirestore,
  doc,
  getDoc,
  collection,
  serverTimestamp,
  setDoc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { Store } from '@ngrx/store';
import { RoleService } from '../role/role.service';
import { InvitationService } from '../invitation/invitation.service';
import { Team } from 'src/app/interfaces/team.interface';
import { mapTeamDocSnapshotToEntity } from 'src/app/utils/data';
import * as TeamActions from 'src/app/store/team/team.actions';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  private RESOURCE = 'teams';

  constructor(
    private store: Store,
    private roleService: RoleService,
    private invitationService: InvitationService
  ) {}

  async get(id: string): Promise<Team | undefined> {
    const db = getFirestore();

    const docRef = doc(db, `${this.RESOURCE}`, id);
    const docSnapshot = await getDoc(docRef);

    const entity = mapTeamDocSnapshotToEntity(docSnapshot);
    if (entity) this.store.dispatch(TeamActions.setOne({ entity }));
    return entity;
  }

  async create(draft: Partial<Team>): Promise<Team | undefined> {
    const db = getFirestore();

    const id = doc(collection(getFirestore(), `${this.RESOURCE}`)).id;
    const timestamp = serverTimestamp();

    const docRef = doc(db, `${this.RESOURCE}`, id);

    await Promise.all([
      setDoc(docRef, {
        ...draft,
        id,
        updatedAt: timestamp,
        createdAt: timestamp,
        timestamp,
      }),
      this.roleService.create(id, {
        isAdmin: true,
        isCreator: true,
        isActive: true,
      }),
      this.invitationService.create(id, {}),
    ]);

    return this.get(id);
  }

  async update(id: string, draft: Partial<Team>): Promise<Team | undefined> {
    const db = getFirestore();
    const docRef = doc(db, `${this.RESOURCE}`, id);
    const timestamp = serverTimestamp();

    try {
      await updateDoc(docRef, { ...draft, updatedAt: timestamp, timestamp });
    } catch (err) {
      console.error(err);
      return undefined;
    }

    return this.get(id);
  }

  /**
   *
   *
   *
   *
   */

  async getActive(): Promise<Team | undefined> {
    const role = await this.roleService.getActive();

    if (!role) return undefined;
    const parts = role.id.split('-');

    if (parts.length != 2) {
      return undefined;
    }

    const id = parts[0];
    const resource = await this.get(id);

    if (resource) this.store.dispatch(TeamActions.select({ id: resource.id }));

    return resource;
  }

  async activate(teamId: string): Promise<boolean> {
    const didActivate = await this.roleService.activate(teamId);

    if (!didActivate) return false;

    this.store.dispatch(TeamActions.select({ id: teamId }));

    return true;
  }

  /**
   *
   *
   *
   *
   */

  async list(): Promise<Team[]> {
    const roles = await this.roleService.list();

    const resources = await Promise.all(
      roles.map(async (role) => await this.get(role.teamId))
    );

    return resources;
  }

  /**
   *
   *
   *
   *
   */

  async hasIntegration(
    teamId: string,
    integrationId: string
  ): Promise<boolean> {
    const db = getFirestore();

    const docRef = doc(
      db,
      `${this.RESOURCE}`,
      teamId,
      'integrations',
      integrationId
    );
    const docSnapshot = await getDoc(docRef);

    return docSnapshot.exists();
  }

  async getHookForAllowlist(
    teamId: string,
    integrationId: string
  ): Promise<any | undefined> {
    const db = getFirestore();

    const docRef = doc(
      db,
      `${this.RESOURCE}`,
      teamId,
      'integrations',
      integrationId,
      'hooks',
      'allowlist'
    );
    const docSnapshot = await getDoc(docRef);

    return docSnapshot.data();
  }

  async setHookForAllowlist(
    teamId: string,
    integrationId: string,
    data: { base: string; table: string; view: string; field: string }
  ): Promise<boolean> {
    const db = getFirestore();

    const docRef = doc(
      db,
      `${this.RESOURCE}`,
      teamId,
      'integrations',
      integrationId,
      'hooks',
      'allowlist'
    );

    await setDoc(docRef, data);

    return true;
  }

  async deleteHookForAllowlist(
    teamId: string,
    integrationId: string
  ): Promise<boolean> {
    const db = getFirestore();

    const docRef = doc(
      db,
      `${this.RESOURCE}`,
      teamId,
      'integrations',
      integrationId,
      'hooks',
      'allowlist'
    );

    await deleteDoc(docRef);

    return true;
  }

  async getHookForCheckins(
    teamId: string,
    integrationId: string
  ): Promise<any | undefined> {
    const db = getFirestore();

    const docRef = doc(
      db,
      `${this.RESOURCE}`,
      teamId,
      'integrations',
      integrationId,
      'hooks',
      'checkins'
    );
    const docSnapshot = await getDoc(docRef);

    return docSnapshot.data();
  }

  async getHookForRSVPs(
    teamId: string,
    integrationId: string
  ): Promise<any | undefined> {
    const db = getFirestore();

    const docRef = doc(
      db,
      `${this.RESOURCE}`,
      teamId,
      'integrations',
      integrationId,
      'hooks',
      'rsvps'
    );
    const docSnapshot = await getDoc(docRef);

    return docSnapshot.data();
  }
}
