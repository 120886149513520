import { createReducer, on } from '@ngrx/store';

import * as actions from './core.actions';

export interface State {
  didInitFirebase: boolean;
  authenticated: boolean | null;
  splitpane: { isActive: boolean };
  next: string | null;
  ready: boolean;
}

export const initialState: Readonly<State> = {
  didInitFirebase: false,
  authenticated: null,
  splitpane: { isActive: false },
  next: null,
  ready: false,
};

export const reducer = createReducer(
  initialState,

  on(actions.didInitFirebase, (state) => ({ ...state, didInitFirebase: true })),

  on(actions.setAuthenticated, (state, { authenticated }) => ({
    ...state,
    authenticated,
  })),

  on(actions.setSplitpaneActive, (state, { isActive }) => ({
    ...state,
    splitpane: {
      ...state.splitpane,
      isActive,
    },
  })),

  on(actions.setNext, (state, { next }) => ({ ...state, next })),

  on(actions.logout, (state) => ({ ...state })),

  on(actions.ready, (state) => ({ ...state, ready: true }))
);
