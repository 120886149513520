import {
  initialState as initialCoreState,
  State as CoreState,
} from './core/core.reducers';
import {
  initialState as initialWeb3State,
  State as Web3State,
} from './web3/web3.reducers';
import {
  initialState as initialRoleState,
  State as RoleState,
} from './role/role.reducers';
import {
  initialState as initialTeamState,
  State as TeamState,
} from './team/team.reducers';

export interface AppState {
  core: CoreState;
  web3: Web3State;
  roles: RoleState;
  teams: TeamState;
}

export const initialState: AppState = {
  core: initialCoreState,
  web3: initialWeb3State,
  roles: initialRoleState,
  teams: initialTeamState,
};
