import { createReducer, on } from '@ngrx/store';

import * as actions from './web3.actions';

export interface State {
  supported: boolean;
  connected: boolean | null;
  accounts: string[];
}

export const initialState: Readonly<State> = {
  supported: false,
  connected: null,
  accounts: [],
};

export const reducer = createReducer(
  initialState,

  on(actions.setSupported, (state, { supported }) => ({
    ...state,
    supported,
  })),

  on(actions.setAccounts, (state, { accounts }) => ({
    ...state,
    accounts,
    connected: accounts.length > 0,
  }))
);
