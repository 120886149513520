export const secrets = {
  firebase: {
    apiKey: 'AIzaSyBtmVE_UosANf4iyhATXyBK62IgQEs1uWA',
    authDomain: 'clubpass-63a0a.firebaseapp.com',
    projectId: 'clubpass-63a0a',
    storageBucket: 'clubpass-63a0a.appspot.com',
    messagingSenderId: '2685197345',
    appId: '1:2685197345:web:ff9d1bfd6836df94741760',
    measurementId: 'G-V2KJELJVDK',
  },
};
