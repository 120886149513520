import { createSelector, createFeatureSelector } from '@ngrx/store';

import { State } from './web3.reducers';

export const selectState = createFeatureSelector<State>('web3');

export const supported = createSelector(
  selectState,
  (state) => state.supported
);

export const connected = createSelector(
  selectState,
  (state) => state.connected
);

export const accounts = createSelector(selectState, (state) => state.accounts);
