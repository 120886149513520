import { ethers } from 'ethers';

import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';

import * as Web3Actions from 'src/app/store/web3/web3.actions';
import * as Web3Selectors from 'src/app/store/web3/web3.selectors';

@Injectable({
  providedIn: 'root',
})
export class Web3Service {
  provider?: ethers.providers.Web3Provider;

  constructor(private store: Store) {
    this.setupProvider();
  }

  async connect(provider: 'metamask'): Promise<string | undefined> {
    if (!this.isSupported()) return undefined;
    await this.provider.send('eth_requestAccounts', []);

    return await this.store
      .select(Web3Selectors.accounts)
      .pipe(
        map((list) => (list.length > 0 ? list[0] : undefined)),
        take(1)
      )
      .toPromise();
  }

  async list(): Promise<string[]> {
    if (!this.isSupported()) return [];

    const accounts = await this.provider.listAccounts();

    return accounts.map((address) => this.normalize(address));
  }

  private async setupProvider() {
    if (!this.isSupported()) return;

    this.provider = new ethers.providers.Web3Provider((window as any).ethereum);

    (window as any).ethereum.on('accountsChanged', async (accounts: string[]) =>
      this.store.dispatch(
        Web3Actions.setAccounts({
          accounts: accounts.map((address) => this.normalize(address)),
        })
      )
    );
  }

  isSupported(): boolean {
    return window.hasOwnProperty('ethereum');
  }

  private normalize(address: string): string {
    return address.toLowerCase();
  }
}
