import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { TeamService } from 'src/app/services/team/team.service';

import * as TeamSelectors from 'src/app/store/team/team.selectors';

@Component({
  selector: 'app-team-picker',
  templateUrl: './team-picker.component.html',
  styleUrls: ['./team-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamPickerPopover implements OnInit {
  loading: boolean = true;

  list$ = combineLatest([
    this.store.select(TeamSelectors.selectSelected),
    this.store.select(TeamSelectors.selectAll),
  ]).pipe(
    map(([selected, list]) => list.filter((item) => item.id !== selected?.id))
  );

  constructor(
    private store: Store,
    private ref: ChangeDetectorRef,
    private teamService: TeamService,
    private popoverCtrl: PopoverController
  ) {}

  ngOnInit() {
    this.load();
  }

  async load() {
    this.loading = true;

    await this.teamService.list();

    this.loading = false;

    this.ref.markForCheck();
  }

  select(id: string) {
    this.popoverCtrl.dismiss(id, 'SELECT');
  }

  create() {
    this.popoverCtrl.dismiss(undefined, 'CREATE');
  }

  manage() {
    this.store
      .select(TeamSelectors.selectSelected)
      .pipe(take(1))
      .subscribe((team) => {
        this.popoverCtrl.dismiss(team?.id, 'MANAGE');
      });
  }

  trackByFn(index: number, item: any): any {
    return item?.id || index;
  }
}
