import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { TeamService } from 'src/app/services/team/team.service';

import * as CoreSelectors from 'src/app/store/core/core.selectors';

@Injectable({
  providedIn: 'root',
})
export class HasTeamGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store,
    private teamService: TeamService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const authenticated = await this.store
      .select(CoreSelectors.selectAuthenticated)
      .pipe(
        filter((authenticated) => authenticated !== null),
        take(1)
      )
      .toPromise();

    if (!authenticated) return false;

    const teams = await this.teamService.list();
    if (teams.length === 0) return this.router.parseUrl('/start/team');

    // const team = await this.teamService.getActive();
    // if (team === undefined) return this.router.parseUrl('/start/team');

    return true;
  }
}
