import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

import { Store } from '@ngrx/store';

import { filter, take } from 'rxjs/operators';

import * as CoreSelectors from 'src/app/store/core/core.selectors';

@Injectable({
  providedIn: 'root',
})
export class TesterGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const authenticated = await this.store
      .select(CoreSelectors.selectAuthenticated)
      .pipe(
        filter((authenticated) => authenticated !== null),
        take(1)
      )
      .toPromise();

    if (!authenticated) return false;

    const auth = getAuth();
    const email = auth.currentUser?.email;
    if (!email) return false;

    const db = getFirestore();
    const isTester = (await getDoc(doc(db, `/testers/${email}`))).exists();

    if (!isTester) return this.router.parseUrl('/home');

    return true;
  }
}
