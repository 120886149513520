import { createSelector, createFeatureSelector } from '@ngrx/store';

import { State } from './team.reducers';

export const selectState = createFeatureSelector<State>('teams');

export const selectIds = createSelector(selectState, (state) => state.ids);

export const selectEntities = createSelector(
  selectState,
  (state) => state.entities
);

export const selectAll = createSelector(selectState, (state) =>
  state.ids.map((id) => state.entities[id]).filter((item) => item !== undefined)
);

export const selectSelected = createSelector(selectState, (state) =>
  state.selectedId ? state.entities[state.selectedId] : undefined
);
