import { createAction, props } from '@ngrx/store';

import { Role } from 'src/app/interfaces/role.interface';

export const select = createAction('[Role] Select', props<{ id: string }>());

export const setOne = createAction(
  '[Role/API] Set One',
  props<{ entity: Role }>()
);

export const addMany = createAction(
  '[Role/API] addMany',
  props<{ list: Role[] }>()
);
