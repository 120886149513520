import { createSelector, createFeatureSelector } from '@ngrx/store';

import { State } from './core.reducers';

export const selectCoreState = createFeatureSelector<State>('core');

export const selectDidInitFirebase = createSelector(
  selectCoreState,
  (state) => state.didInitFirebase
);

export const selectAuthenticated = createSelector(
  selectCoreState,
  (state) => state.authenticated
);

export const selectSplitpane = createSelector(
  selectCoreState,
  (state) => state.splitpane
);

export const selectNext = createSelector(
  selectCoreState,
  (state) => state.next
);

export const selectReady = createSelector(
  selectCoreState,
  (state) => state.ready
);
