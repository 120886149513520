import { Injectable } from '@angular/core';

import { getAuth } from 'firebase/auth';
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  serverTimestamp,
  setDoc,
  query,
  where,
  getDocs,
  limit,
  orderBy,
  writeBatch,
} from 'firebase/firestore';

import { Store } from '@ngrx/store';

import { Role } from 'src/app/interfaces/role.interface';

import * as RoleActions from 'src/app/store/role/role.actions';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private RESOURCE = 'roles';

  constructor(private store: Store) {}

  async getActive(): Promise<Role | undefined> {
    const auth = getAuth();
    const uid = auth.currentUser?.uid;

    if (!uid) return undefined;

    const db = getFirestore();

    const collectionRef = collection(db, `${this.RESOURCE}`);
    const collectionQuery = query(
      collectionRef,
      where('userId', '==', uid),
      orderBy('isActive', 'desc'),
      limit(5)
    );

    const snapshot = await getDocs(collectionQuery);

    let resource: Role | undefined = undefined;

    if (!snapshot.empty) {
      const id = snapshot.docs[0].id;
      const data = snapshot.docs[0].data();
      const timestamp = data.timestamp?.toDate();

      resource = {
        id,
        ...data,
        timestamp,
      } as Role;

      this.store.dispatch(RoleActions.setOne({ entity: resource }));
      this.store.dispatch(RoleActions.select({ id: resource.id }));
    }

    return resource;
  }

  async get(id: string): Promise<Role | undefined> {
    const db = getFirestore();

    const docRef = doc(db, `${this.RESOURCE}`, id);
    const docSnapshot = await getDoc(docRef);

    if (!docSnapshot.exists()) return undefined;

    const data = docSnapshot.data();

    const resource = {
      ...data,
      id: docSnapshot.id,
      timestamp: data.timestamp?.toDate(),
    } as Role;

    if (resource) this.store.dispatch(RoleActions.setOne({ entity: resource }));

    return resource;
  }

  async list(): Promise<Role[]> {
    const auth = getAuth();
    const userId = auth.currentUser?.uid;

    if (userId === undefined) return [];

    const db = getFirestore();

    const collectionRef = collection(db, `${this.RESOURCE}`);
    const collectionQuery = query(
      collectionRef,
      where('userId', '==', userId),
      orderBy('isActive', 'desc')
    );

    const snapshot = await getDocs(collectionQuery);

    const resources = snapshot.docs
      .map((doc) => {
        const data = doc.data();
        if (!data) return undefined;

        const id = doc.id;
        const timestamp = data.timestamp?.toDate();

        return { ...data, id, timestamp } as Role;
      })
      .filter((item) => item !== undefined);

    return resources;
  }

  async activate(teamId: string): Promise<boolean> {
    const auth = getAuth();
    const userId = auth.currentUser?.uid;

    if (userId === undefined) return false;

    const db = getFirestore();
    const batch = writeBatch(db);

    const idToActivate = `${teamId}-${userId}`;

    const list = await this.list();
    list.forEach((role) =>
      batch.update(doc(db, `${this.RESOURCE}`, role.id), {
        isActive: role.id === idToActivate,
      })
    );

    try {
      await batch.commit();
      this.store.dispatch(RoleActions.select({ id: idToActivate }));
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async create(
    teamId: string,
    draft: Partial<Role>
  ): Promise<Role | undefined> {
    const auth = getAuth();
    const userId = auth.currentUser?.uid;

    if (userId === undefined) return undefined;

    const db = getFirestore();

    const id = `${teamId}-${userId}`;
    const timestamp = serverTimestamp();

    const docRef = doc(db, `${this.RESOURCE}`, id);

    await setDoc(docRef, {
      ...draft,
      teamId,
      userId,
      id,
      updatedAt: timestamp,
      createdAt: timestamp,
      timestamp,
    });

    return this.get(id);
  }
}
