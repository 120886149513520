import { Injectable, isDevMode } from '@angular/core';

import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { from } from 'rxjs';
import { tap, map, filter, mergeMap } from 'rxjs/operators';

import { initializeApp } from 'firebase/app';

import {
  getAuth,
  connectAuthEmulator,
  onAuthStateChanged,
  signOut,
} from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

import { environment } from '../../../environments/environment';

import { Web3Service } from 'src/app/services/web3/web3.service';

import * as CoreActions from './core.actions';
import * as Web3Actions from './../web3/web3.actions';

@Injectable()
export class CoreEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private web3Service: Web3Service
  ) {}

  onInitCreateFirebaseApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      map((_) => {
        //
        // initialize firebase

        const app = initializeApp({ ...environment.secrets.firebase });

        //
        // connect analytics for prod

        if (!isDevMode()) getAnalytics(app);

        //
        // connect to emulators in dev environment

        if (environment.useEmulators) {
          // auth
          const auth = getAuth();
          connectAuthEmulator(
            auth,
            `http://${environment.emulators.auth.host}:${environment.emulators.auth.port}`
          );

          // firestore
          const db = getFirestore();
          connectFirestoreEmulator(
            db,
            environment.emulators.firestore.host,
            environment.emulators.firestore.port
          );

          // storage
          const storage = getStorage();
          connectStorageEmulator(
            storage,
            environment.emulators.storage.host,
            environment.emulators.storage.port
          );

          // functions
          const functions = getFunctions(app);
          connectFunctionsEmulator(
            functions,
            environment.emulators.functions.host,
            environment.emulators.functions.port
          );
        }

        //
        // notify firebase is init

        return CoreActions.didInitFirebase();
      })
    )
  );

  onInitSubscribeToAuthChanges = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROOT_EFFECTS_INIT),
        tap((_) => {
          const auth = getAuth();
          onAuthStateChanged(auth, (user) => {
            this.store.dispatch(
              CoreActions.setAuthenticated({
                authenticated: user !== null,
              })
            );
          });
        })
      ),
    { dispatch: false }
  );

  onInitCheckWeb3Support$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      map((_) =>
        Web3Actions.setSupported({ supported: this.web3Service.isSupported() })
      )
    )
  );

  onWeb3SupportListAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Web3Actions.setSupported),
      filter(({ supported }) => supported),
      mergeMap((_) => from(this.web3Service.list())),
      map((accounts) => Web3Actions.setAccounts({ accounts }))
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.logout),
        tap((_) => {
          const auth = getAuth();
          signOut(auth)
            .then(() => {})
            .catch((err) => console.error(err));
        })
      ),
    { dispatch: false }
  );
}
