import { ActionReducer, MetaReducer } from '@ngrx/store';

import { logout } from './core/core.actions';

import { initialState } from './app.state';

export function resetStateOnLogout(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function (state, action) {
    if (action.type === logout.type) {
      const resetState = Object.keys(state)
        .map((key) =>
          ['core'].includes(key)
            ? { key, value: state[key] }
            : { key, value: initialState[key] }
        )
        .reduce((acc, curr) => ({ ...acc, [curr.key]: curr.value }), {});

      return reducer(resetState, action);
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [resetStateOnLogout];
