import { Injectable } from '@angular/core';

import {
  getFirestore,
  doc,
  getDoc,
  collection,
  serverTimestamp,
  setDoc,
  query,
  where,
  getDocs,
  limit,
} from 'firebase/firestore';

import { Store } from '@ngrx/store';

import { Invitation } from 'src/app/interfaces/invitation.interface';

@Injectable({
  providedIn: 'root',
})
export class InvitationService {
  private RESOURCE = 'invitations';

  constructor(private store: Store) {}

  async get(id: string): Promise<Invitation | undefined> {
    const db = getFirestore();

    const docRef = doc(db, `${this.RESOURCE}`, id);
    const docSnapshot = await getDoc(docRef);

    if (!docSnapshot.exists()) return undefined;

    const data = docSnapshot.data();

    const resource = {
      ...data,
      id: docSnapshot.id,
      timestamp: data.timestamp?.toDate(),
    } as Invitation;

    return resource;
  }

  async lookup(teamId: string): Promise<Invitation | undefined> {
    const db = getFirestore();

    const collectionRef = collection(db, `${this.RESOURCE}`);
    const collectionQuery = query(
      collectionRef,
      where('teamId', '==', teamId),
      limit(1)
    );

    const snapshot = await getDocs(collectionQuery);

    let resource: Invitation | undefined = undefined;

    if (!snapshot.empty) {
      const id = snapshot.docs[0].id;
      const data = snapshot.docs[0].data();
      const timestamp = data.timestamp?.toDate();

      resource = {
        id,
        ...data,
        timestamp,
      } as Invitation;
    }

    return resource;
  }

  async create(
    teamId: string,
    draft: Partial<Invitation>
  ): Promise<Invitation | undefined> {
    const db = getFirestore();

    const id = doc(collection(getFirestore(), `${this.RESOURCE}`)).id;
    const timestamp = serverTimestamp();

    const docRef = doc(db, `${this.RESOURCE}`, id);

    await setDoc(docRef, {
      ...draft,
      id,
      teamId,
      timestamp,
    });

    return this.get(id);
  }
}
