import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

// override anchors so they open in new tabs
// ref: https://github.com/jfcere/ngx-markdown/issues/79
export const markedOptionsFactory = (): MarkedOptions => {
  const renderer = new MarkedRenderer();
  const linkRenderer = renderer.link;

  renderer.link = (href, title, text) => {
    const html = linkRenderer.call(renderer, href, title, text);
    return html.replace(
      /^<a /,
      '<a role="link" tabindex="0" target="_blank" rel="nofollow noopener noreferrer" '
    );
  };

  return {
    renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: false,
  };
};
