import { createAction, props } from '@ngrx/store';

export const setSupported = createAction(
  '[Web3] Set Supported',
  props<{ supported: boolean }>()
);

export const setAccounts = createAction(
  '[Web3] Set Accounts',
  props<{ accounts: string[] }>()
);
