import {
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
import { Team } from '../interfaces/team.interface';
import { Claim } from 'src/app/interfaces/claim.interface';
import { Pass } from '../interfaces/pass.interface';
import { Access, AllowlistEntry } from '../interfaces/access.interface';
import { Checkin } from '../interfaces/checkin.interface';
import { Event, RSVP } from '../interfaces/event.interface';
import { TokenTemplate } from '../interfaces/token-template.interface';
import { Token } from '../interfaces/token.interface';
import { Integration } from '../interfaces/integration.interface';
import { Tag } from '../interfaces/tag.interface';

export const mapTeamDocSnapshotToEntity = (
  snapshot: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>
): Team | undefined => {
  const data = snapshot.data();
  if (!data) return undefined;

  const id = snapshot.id;

  const entity = {
    ...data,
    id,
    updatedAt: data?.updatedAt?.toDate() ?? new Date(),
    createdAt: data?.createdAt?.toDate() ?? new Date(),
    timestamp: data?.timestamp?.toDate() ?? new Date(),
  } as Team;

  return entity;
};

export const mapTagDocSnapshotToEntity = (
  snapshot: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>
): Tag | undefined => {
  const data = snapshot.data();
  if (!data) return undefined;

  const id = snapshot.id;

  const entity = {
    ...data,
    id,
    timestamp: data?.timestamp?.toDate() ?? new Date(),
  } as Tag;

  return entity;
};

export const mapAccessDocSnapshotToEntity = (
  snapshot: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>
): Access | undefined => {
  const data = snapshot.data();
  if (!data) return undefined;

  const id = snapshot.id;

  const entity = {
    ...data,
    id,
    updatedAt: data?.updatedAt?.toDate() ?? new Date(),
    createdAt: data?.createdAt?.toDate() ?? new Date(),
    timestamp: data?.timestamp?.toDate() ?? new Date(),
  } as Access;

  return entity;
};

export const mapAllowlistEntryDocSnapshotToEntity = (
  snapshot: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>
): AllowlistEntry | undefined => {
  const data = snapshot.data();

  if (!data) return undefined;

  const id = snapshot.id;

  const entity = {
    ...data,
    id,
  } as AllowlistEntry;

  return entity;
};

export const mapPassDocSnapshotToEntity = (
  snapshot: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>
): Pass | undefined => {
  const data = snapshot.data();
  if (!data) return undefined;

  const id = snapshot.id;

  const entity = {
    ...data,
    id,
    updatedAt: data?.updatedAt?.toDate() ?? new Date(),
    createdAt: data?.createdAt?.toDate() ?? new Date(),
    timestamp: data?.timestamp?.toDate() ?? new Date(),
  } as Pass;

  return entity;
};

export const mapClaimDocSnapshotToEntity = (
  snapshot: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>
): Claim | undefined => {
  const data = snapshot.data();
  if (!data) return undefined;

  const id = snapshot.id;

  const entity = {
    ...data,
    id,
    updatedAt: data?.updatedAt?.toDate() ?? new Date(),
    createdAt: data?.createdAt?.toDate() ?? new Date(),
    timestamp: data?.timestamp?.toDate() ?? new Date(),
  } as Claim;

  return entity;
};

export const mapCheckinDocSnapshotToEntity = (
  snapshot: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>
): Checkin | undefined => {
  const data = snapshot.data();
  if (!data) return undefined;

  const id = snapshot.id;

  const entity = {
    ...data,
    id,
    timestamp: data?.timestamp?.toDate() ?? new Date(),
  } as Checkin;

  return entity;
};

export const mapRSVPDocSnapshotToEntity = (
  snapshot: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>
): RSVP | undefined => {
  const data = snapshot.data();
  if (!data) return undefined;

  const id = snapshot.id;

  const entity = {
    ...data,
    id,
    timestamp: data?.timestamp?.toDate() ?? new Date(),
  } as RSVP;

  return entity;
};

export const mapEventDocSnapshotToEntity = (
  snapshot: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>
): Event | undefined => {
  const data = snapshot.data();
  if (!data) return undefined;

  const id = snapshot.id;

  const entity = {
    ...data,
    id,
    datetime: data?.datetime?.toDate() ?? new Date(),
    updatedAt: data?.updatedAt?.toDate() ?? new Date(),
    createdAt: data?.createdAt?.toDate() ?? new Date(),
    timestamp: data?.timestamp?.toDate() ?? new Date(),
  } as Event;

  return entity;
};

export const mapTokenTemplateDocSnapshotToEntity = (
  snapshot: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>
): TokenTemplate | undefined => {
  const data = snapshot.data();
  if (!data) return undefined;

  const id = snapshot.id;

  const entity = {
    ...data,
    id,
    updatedAt: data?.updatedAt?.toDate() ?? new Date(),
    createdAt: data?.createdAt?.toDate() ?? new Date(),
  } as TokenTemplate;

  return entity;
};

export const mapTokenDocSnapshotToEntity = (
  snapshot: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>
): Token | undefined => {
  const data = snapshot.data();
  if (!data) return undefined;

  const id = snapshot.id;

  const entity = {
    ...data,
    id,
    timestamp: data?.updatedAt?.toDate() ?? new Date(),
  } as Token;

  return entity;
};

export const mapIntegrationDocSnapshotToEntity = (
  snapshot: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>
): Integration | undefined => {
  const data = snapshot.data();
  if (!data) return undefined;

  const id = snapshot.id;

  const entity = {
    ...data,
    id,
    timestamp: data?.timestamp?.toDate() ?? new Date(),
  } as Integration;

  return entity;
};
