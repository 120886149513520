import { reducer as coreReducer } from './core/core.reducers';

import { reducer as web3Reducer } from './web3/web3.reducers';

import { reducer as roleReducer } from './role/role.reducers';
import { reducer as teamReducer } from './team/team.reducers';

export const reducers = {
  core: coreReducer,
  web3: web3Reducer,
  roles: roleReducer,
  teams: teamReducer,
};
