import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import * as CoreSelectors from 'src/app/store/core/core.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const authenticated = await this.store
      .select(CoreSelectors.selectAuthenticated)
      .pipe(
        filter((authenticated) => authenticated !== null),
        take(1)
      )
      .toPromise();

    if (!authenticated) return this.router.parseUrl('/authenticate');

    return true;
  }
}
