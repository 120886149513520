import { createAction, props } from '@ngrx/store';

import { Team } from 'src/app/interfaces/team.interface';

export const select = createAction('[Teams] Select', props<{ id: string }>());

export const setOne = createAction(
  '[Teams/API] Set One',
  props<{ entity: Team }>()
);

export const addMany = createAction(
  '[Teams/API] addMany',
  props<{ list: Team[] }>()
);
