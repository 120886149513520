import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { PopoverController, NavController } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';

import { TeamPickerPopover } from './shared/popovers/team-picker/team-picker.component';

import * as CoreSelectors from 'src/app/store/core/core.selectors';
import * as TeamSelectors from 'src/app/store/team/team.selectors';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  splitpaneDisabled$ = this.store.select(CoreSelectors.selectSplitpane).pipe(
    map(({ isActive }) => !isActive),
    distinctUntilChanged()
  );

  authenticated$ = this.store
    .select(CoreSelectors.selectAuthenticated)
    .pipe(distinctUntilChanged());

  team$ = this.store
    .select(TeamSelectors.selectSelected)
    .pipe(distinctUntilChanged((x, y) => x?.id === y?.id));

  options$ = combineLatest([
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart),
      map((ev: any) => ev?.url ?? ''),
      distinctUntilChanged()
    ),
    this.authenticated$,
    this.team$,
  ]).pipe(
    map(([url, authenticated, team]) => {
      if (!authenticated || !team) return [];

      return [
        {
          key: '/dashboard',
          routerLink: ['/dashboard'],
          icon: 'bar-chart-outline',
          title: 'Dashboard',
          detail: false,
          detailIcon: 'chevron-forward-outline',
          active: url.startsWith('/dashboard'),
        },

        {
          key: '/manage/team',
          routerLink: ['/manage/team', team.id],
          icon: 'id-card-outline',
          title: 'Membership',
          detail: false,
          detailIcon: 'chevron-forward-outline',
          active: url.includes('/manage/team'),
        },

        {
          key: '/access',
          routerLink: ['/access'],
          icon: 'lock-closed-outline',
          title: 'Access',
          detail: false,
          detailIcon: 'chevron-forward-outline',
          active: url.startsWith('/access'),
        },

        {
          key: '/design',
          routerLink: ['/design'],
          icon: 'color-palette-outline',
          title: 'Design',
          detail: false,
          detailIcon: 'chevron-forward-outline',
          active: url.startsWith('/design'),
        },

        {
          key: '/members',
          routerLink: ['/members'],
          icon: 'people-outline',
          title: 'Members',
          detail: false,
          detailIcon: 'chevron-forward-outline',
          active: url.startsWith('/members') || url.includes('/manage/member'),
        },

        {
          key: '/events',
          routerLink: ['/events'],
          icon: 'calendar-outline',
          title: 'Events',
          detail: false,
          detailIcon: 'chevron-forward-outline',
          active:
            url.startsWith('/events') ||
            url.includes('/start/event') ||
            url.includes('/manage/event'),
        },

        {
          key: '/rsvps',
          routerLink: ['/rsvps'],
          icon: 'ticket-outline',
          title: 'RSVPs',
          detail: false,
          detailIcon: 'chevron-forward-outline',
          active: url.startsWith('/rsvps'),
        },

        {
          key: '/checkins',
          routerLink: ['/checkins'],
          icon: 'checkbox-outline',
          title: 'Checkins',
          detail: false,
          detailIcon: 'chevron-forward-outline',
          active: url.startsWith('/checkins'),
        },

        {
          key: '/tokens',
          routerLink: ['/tokens'],
          icon: 'sparkles-outline',
          title: 'Tokens',
          detail: false,
          detailIcon: 'chevron-forward-outline',
          active: url.startsWith('/tokens') || url.includes('/manage/token'),
        },

        {
          key: '/integrations',
          routerLink: ['/integrations'],
          icon: 'git-branch-outline',
          title: 'Integrations',
          detail: false,
          detailIcon: 'chevron-forward-outline',
          active:
            url.startsWith('/integrations') ||
            url.includes('/manage/integration'),
        },

        {
          key: '/scan',
          routerLink: ['/scan'],
          icon: 'barcode-outline',
          title: 'Scanner App',
          detail: true,
          detailIcon: 'open-outline',
          active: false,
        },
      ];
    })
  );

  constructor(
    private store: Store,
    private router: Router,
    private navCtrl: NavController,
    private popoverCtrl: PopoverController
  ) {}

  async chooseTeam(ev: MouseEvent) {
    const popover = await this.popoverCtrl.create({
      component: TeamPickerPopover,
      event: ev,
      showBackdrop: false,
      cssClass: ['app-popover'],
    });

    await popover.present();

    const { role, data } = await popover.onDidDismiss();

    switch (role) {
      case 'SELECT': {
        this.navCtrl.navigateRoot(['/', 'dashboard', { team: data }]);
        break;
      }

      case 'CREATE': {
        this.navCtrl.navigateRoot(['/', 'start', 'team']);
        break;
      }

      case 'MANAGE': {
        this.navCtrl.navigateRoot(['/', 'manage', 'team', data]);
        break;
      }

      default:
        break;
    }
  }

  trackByFn(index: number, item: any): any {
    return item?.key || index;
  }
}
