import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthenticatedGuard } from './guards/authenticated/authenticated.guard';
import { TesterGuard } from './guards/tester/tester.guard';
import { HasTeamGuard } from './guards/has-team/has-team.guard';
import { IsSigninWithEmailLinkGuard } from './guards/is-signin-with-email-link/is-signin-with-email-link.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthenticatedGuard],
  },

  {
    path: 'pass/:id',
    loadChildren: () =>
      import('./pages/pass/pass.module').then((m) => m.PassPageModule),
    canActivate: [],
  },

  {
    path: 'event/:id',
    loadChildren: () =>
      import('./pages/event/event.module').then((m) => m.EventPageModule),
    canActivate: [],
  },

  {
    path: 'here',
    loadChildren: () =>
      import('./pages/presence/presence.module').then(
        (m) => m.PresencePageModule
      ),
    canActivate: [AuthenticatedGuard],
  },

  {
    path: 'member/:id',
    loadChildren: () =>
      import('./pages/member/member.module').then((m) => m.MemberPageModule),
    canActivate: [],
  },

  {
    path: 'authenticate',
    loadChildren: () =>
      import('./pages/authenticate/authenticate.module').then(
        (m) => m.AuthenticatePageModule
      ),
    canActivate: [],
  },

  {
    path: 'complete/auth',
    loadChildren: () =>
      import('./pages/complete-link-auth/complete-link-auth.module').then(
        (m) => m.CompleteLinkAuthPageModule
      ),
    canActivate: [IsSigninWithEmailLinkGuard],
  },

  {
    path: '404',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(
        (m) => m.NotFoundPageModule
      ),
    canActivate: [],
  },

  // ----------------------------

  {
    path: 'start/team',
    loadChildren: () =>
      import('./pages/team-create/team-create.module').then(
        (m) => m.TeamCreatePageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard],
  },

  {
    path: 'join/team/:code',
    loadChildren: () =>
      import('./pages/team-join/team-join.module').then(
        (m) => m.TeamJoinPageModule
      ),
    canActivate: [],
  },

  {
    path: 'manage/team/:id',
    loadChildren: () =>
      import('./pages/team-manage/team-manage.module').then(
        (m) => m.TeamManagePageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },

  // ----------------------------

  {
    path: 'scan',
    loadChildren: () =>
      import('./pages/scan/scan.module').then((m) => m.ScanPageModule),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },

  // ----------------------------

  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },

  {
    path: 'design',
    loadChildren: () =>
      import('./pages/design/design.module').then((m) => m.DesignPageModule),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },

  {
    path: 'access',
    loadChildren: () =>
      import('./pages/access/access.module').then((m) => m.AccessPageModule),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },

  // ----------------------------

  {
    path: 'members',
    loadChildren: () =>
      import('./pages/member-list/member-list.module').then(
        (m) => m.MemberListPageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },

  {
    path: 'manage/member/:id',
    loadChildren: () =>
      import('./pages/member-manage/member-manage.module').then(
        (m) => m.MemberManagePageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },

  {
    path: 'checkins',
    loadChildren: () =>
      import('./pages/checkin-list/checkin-list.module').then(
        (m) => m.CheckinListPageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },
  {
    path: 'manage/event/:eventId/checkins',
    loadChildren: () =>
      import('./pages/checkin-list/checkin-list.module').then(
        (m) => m.CheckinListPageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },

  {
    path: 'rsvps',
    loadChildren: () =>
      import('./pages/rsvp-list/rsvp-list.module').then(
        (m) => m.RsvpListPageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },
  {
    path: 'manage/event/:eventId/rsvps',
    loadChildren: () =>
      import('./pages/rsvp-list/rsvp-list.module').then(
        (m) => m.RsvpListPageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },

  {
    path: 'events',
    loadChildren: () =>
      import('./pages/event-list/event-list.module').then(
        (m) => m.EventListPageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },
  {
    path: 'start/event',
    loadChildren: () =>
      import('./pages/event-create/event-create.module').then(
        (m) => m.EventCreatePageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },
  {
    path: 'manage/event/:id',
    loadChildren: () =>
      import('./pages/event-manage/event-manage.module').then(
        (m) => m.EventManagePageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },

  {
    path: 'tokens',
    loadChildren: () =>
      import('./pages/token-template-list/token-template-list.module').then(
        (m) => m.TokenTemplateListPageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },
  {
    path: 'start/token',
    loadChildren: () =>
      import('./pages/token-template-create/token-template-create.module').then(
        (m) => m.TokenTemplateCreatePageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },

  {
    path: 'manage/token/:id',
    loadChildren: () =>
      import('./pages/token-template-manage/token-template-manage.module').then(
        (m) => m.TokenTemplateManagePageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },
  {
    path: 'manage/token/:id/people',
    loadChildren: () =>
      import(
        './pages/token-template-manage-people/token-template-manage-people.module'
      ).then((m) => m.TokenTemplateManagePeoplePageModule),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },

  {
    path: 'integrations',
    loadChildren: () =>
      import('./pages/integration-list/integration-list.module').then(
        (m) => m.IntegrationListPageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },
  {
    path: 'start/integration',
    loadChildren: () =>
      import('./pages/integration-create/integration-create.module').then(
        (m) => m.IntegrationCreatePageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },
  {
    path: 'manage/integration/:id',
    loadChildren: () =>
      import('./pages/integration-manage/integration-manage.module').then(
        (m) => m.IntegrationManagePageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard, HasTeamGuard],
  },

  // ----------------------------

  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
    canActivate: [AuthenticatedGuard, TesterGuard],
  },

  // ----------------------------

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },

  { path: '**', redirectTo: '/404' },

  // ----------------------------

  {
    path: 'airtable-hook',
    loadChildren: () =>
      import('./modals/airtable-hook/airtable-hook.module').then(
        (m) => m.AirtableHookModalModule
      ),
  },
  {
    path: 'person-picker',
    loadChildren: () =>
      import('./modals/person-picker/person-picker.module').then(
        (m) => m.PersonPickerModalModule
      ),
  },
  {
    path: 'tag-picker',
    loadChildren: () =>
      import('./modals/tag-picker/tag-picker.module').then(
        (m) => m.TagPickerModalModule
      ),
  },
  {
    path: 'claim-edit',
    loadChildren: () =>
      import('./modals/claim-edit/claim-edit.module').then(
        (m) => m.ClaimEditModalModule
      ),
  },
  {
    path: 'token',
    loadChildren: () =>
      import('./modals/token/token.module').then((m) => m.TokenModalModule),
  },
  {
    path: 'web3-connect',
    loadChildren: () =>
      import('./modals/web3-connect/web3-connect.module').then(
        (m) => m.Web3ConnectModalModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
