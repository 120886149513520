import { createAction, props } from '@ngrx/store';

export const didInitFirebase = createAction('[Core] Did Init Firebase');

export const setAuthenticated = createAction(
  '[Core] Set Authenticated',
  props<{ authenticated: boolean }>()
);

export const setSplitpaneActive = createAction(
  '[Core] Set Active Splitpane',
  props<{ isActive: boolean }>()
);

export const setNext = createAction(
  '[Core] Set Next',
  props<{ next: string }>()
);

export const logout = createAction('[Core] Logout');

export const ready = createAction('[Core] Ready');
