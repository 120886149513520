import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { Action, createReducer, on } from '@ngrx/store';

import { Role } from 'src/app/interfaces/role.interface';

import * as RoleActions from './role.actions';

export interface State extends EntityState<Role> {
  // additional state property
  selectedId: string | null;
}

export const adapter: EntityAdapter<Role> = createEntityAdapter<Role>();

export const initialState: State = adapter.getInitialState({
  selectedId: null,
});

export const _reducer = createReducer(
  initialState,

  on(RoleActions.select, (state, { id }) => ({
    ...state,
    selectedId: id,
  })),

  on(RoleActions.setOne, (state, { entity }) =>
    adapter.setOne(entity, { ...state })
  ),

  on(RoleActions.addMany, (state, { list }) =>
    adapter.addMany(list, { ...state })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return _reducer(state, action);
}
